//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

body {
    @include lib-css(background-color, $page__background-color);
}

* {
    box-sizing: border-box;
}

//
//  Back to top button
//  ---------------------------------------------

.page-wrapper {
    .back-to-top {
        @include lib-css(transition, all 0.2s);
        position: fixed;
        right: $indent__m;
        bottom: 10%;
        background-color: $primary__color;
        color: $color-white;
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        z-index: 200;
        border-radius: 50%;

        &:hover {
            background-color: $secondary__color;
        }
        span {
            display: none;
        }
    }
}

//
//  Global Message Banner
//  ---------------------------------------------

.page-wrapper {
    .header.global-message {
        position: relative;
        z-index: 20;
        display: block;
        background-color: $primary__color;
        color: #3e3d30;
        padding: 8px 0 10px 0;
        text-align: center;
        font-weight: bold;
        font-size: 13px;
        font-family: "Domine", serif;
        text-transform: uppercase;

        @include max-screen($screen__s) {
            font-size: 10px;
        }
        @include max-screen($screen__m) {
            text-align: left;
        }
        .container{
            position: relative;
            padding: 0 133px;

            @include max-screen($screen__m) {
                padding: 0 133px 0 15px;
            }
        }

        //trade button
        #trade-login-button{
            position: absolute;
            right: 0;
            background: #232960;
            display: block;
            top: 0;
            width:113px;
            text-align: center;
            margin-top: -8px;
            height: 36px;
            padding: 9px 10px;
            color: white;
            text-transform: uppercase;
            font-weight: 400;
            font-family: 'Roboto Condensed';

            @include max-screen($screen__s) {
                width:100px;
                padding: 9px 5px;
            }
        }
        a {
          color: #3e3d30;

          .fas {
            position: relative;
            top: 1px;
          }
        }
        p {
            margin: 0;
            font-family: "Domine", serif;
        }
    }
}

//
//  Header
//  ---------------------------------------------
@import '_header';
@import '_mobile-menu';

//
//  Top Panel
//  ---------------------------------------------

.panel.wrapper {
    @include lib-clearfix();
    @include lib-css(background-color, $gray-lightest__color);

    .header.links {
        display: none;
    }
}



//
//  Promo
//  ---------------------------------------------

.page-subheader {
    background-color: $color-white;

    .promo.header {
        text-align: center;

        ul {
            @extend .abs-add-clearfix;
            @extend .abs-reset-list;
            padding-top: $indent__m;

            @include max-screen($screen__l) {
                @include make-row();
            }
            @include min-screen($screen__l) {
                display: flex;
            }

            li {
                @include make-xxs-column(12);
                margin-bottom: $indent__m;
                color: $text__color;

                @include min-screen($screen__l) {
                    flex-grow: 1;
                    float: none;
                    width: auto;
                }
                @include max-screen($screen__l) {
                    display: none;

                    &:first-child {
                        display: block;
                    }
                }

                a {
                    display: block;
                    color: $text__color;

                    .fas,
                    .far {
                        margin-right: $indent__xs;
                        color: $primary__color;
                    }
                }
            }
        }
    }
}

//
//  Other
//  ---------------------------------------------

.page-main {
    > .page-title-wrapper {
        .page-title + .action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }
    &.noscript,
    &.cookie {
        padding: 10px 20px;
        display: block;
        line-height: 1.2em;
        font-size: 16px;
        background: #fee5e8;
        color: #da7a85;
        margin: 0;
    }
    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        border-top: 1px solid #dd7a85;

        .actions {
            margin-top: $indent__s;
        }
    }
    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}

//
//  Footer
//  ---------------------------------------------
@import '_footer';

//
//  Switcher
//  ---------------------------------------------

.page-header,
.page-footer {

    & > .header.content{
        .switcher {
            @include max-screen($screen__l) {
                display: none;
            }
        }
    }
    .switcher {

        float: right;
        margin-top: 45px;
        margin-right: 20px;
        @include lib-font-size(13px);

        @include max-screen($screen__l) {
            float: left;
        }
        @include max-screen($screen__m) {
            width: 100%;
            margin: 0;
        }
        //Language Switcher
        &.switcher.language{
            .switcher-trigger{
                strong{
                    @include max-screen($screen__m) {
                        height: 16px;
                    }
                }
            }
        }

        //Currency switcher
        &-currency{
            .options .action.toggle strong{
                padding-left: 0 !important;
            }
        }

        //universal
        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            .action.toggle {
                color: $text__color;

                &:after {
                    display: none !important;
                }
                strong {
                    padding-left: 27px;
                    background-repeat: no-repeat;
                    background-position: 0 1px;

                    span {
                        display: none;
                    }
                    &:before {
                        display: inline-block;
                        font-size: 14px;
                        color: $green__color;
                        font-weight: bold;

                        @include max-screen($screen__m) {
                            color: #000000;
                            text-decoration: none;
                            display: block;
                            font-weight: 700;
                            padding: 0;
                            font-size: 1.6rem;
                        }
                    }
                    &.language-GBP {
                        &:before {
                             content: "£ GBP";
                         }
                     }
                    &.language-USD {
                         &:before {
                            content: "$ USD";
                        }
                     }
                    &.language-EUR {
                         &:before {
                             content: "€ EUR";
                         }
                    }
                    &.language-AUD {
                        &:before {
                            content: "$ AUD";
                        }
                    }
                    &.language-BRL {
                         &:before {
                              content: "R$ BRL";
                         }
                     }
                    &.language-CAD {
                        &:before {
                             content: "$ CAD";
                        }
                    }
                    &.language-JPY {
                        &:before {
                             content: "¥ JPY";
                         }
                    }
                    &.language-PLN {
                        &:before {
                             content: "zł PLN";
                         }
                    }
                    &.language-RUB {
                        &:before {
                            content: "₽ RUB";
                         }
                    }
                    &.language-CHF {
                        &:before {
                            content: "CHF";
                        }
                    }

                    &:after {
                        @extend .fas;
                        @include fa-icon;
                        content: fa-content($fa-var-caret-down);
                        margin-left: $indent__xs;
                        color: $gray__color;

                        @include max-screen($screen__m) {
                            font-size: 14px;
                            position: absolute;
                            top: 2px;
                            right: -20px;
                        }
                    }
                }
                &.active {
                    strong {
                        &:after {
                            content: fa-content($fa-var-caret-up);
                        }
                    }
                }
            }
            ul.dropdown {
                a {
                    display: block;
                    padding: 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
        .label {
            @extend .abs-visually-hidden;
        }
        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }
        .ie10 &,
        .ie11 & {
            display: block;
            height: 100%;
        }
    }

    .navigation ul {
        padding: 0 8px;
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title + .action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }
            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}
