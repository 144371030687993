//
//  Footer
//  ---------------------------------------------
$footer_border-color:#dcdbd7;

.page-footer {
    background-color: #fbfbfb;
}

.footer {
    &.content {
        .content {
            nav{
                &.social{
                    a{
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
                strong{
                    display: block;
                    margin-bottom: 20px;
                    color: $green-dark__color;
                    @include lib-font-size(14px);
                }
                a{
                    display: block;
                    padding: 5px 0;
                    color: $primary__color;
                }
            }
            .logo{
                margin-bottom: 40px;
                display: block;
            }
            address{
                .tel{
                    font-size:23px;
                    font-weight:bold;
                    font-family: "Domine", serif;
                }
                .email{
                    font-size:17px;
                }
            }
        }
        .footer-contact {
            margin-bottom: $indent__base;
            
            .telephone {
                color: $color-white;
                font-weight: 900;
                @include lib-font-size(18px);
            }
            .address {
                color: $color-white;
                margin-top: $indent__base;
            }
        }
        h4 {
            color: #1b215c;
            margin-bottom: $indent__base;
            margin-top: $indent__s;
            font-weight: 600;
            font-family: "Domine", serif;

            @include lib-font-size(15px);
            
            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
            }
            
            .fas {
                margin-left: $indent__xs;
                
                @include min-screen($screen__m) {
                    display: none;
                }
            }
        }
        p {
            color: $green-dark__color;
            
            img {
                margin-top: $indent__xs;
            }
        }
        .information,
        .legal {
            margin-bottom: $indent__base;
            
            @include max-screen($screen__m) {
                margin-bottom: $indent__s;
            }
            
            ul {
                @extend .abs-reset-list;
                
                @include max-screen($screen__m) {
                    display: none;
                }
                @include min-screen($screen__m) {
                    display: block !important;
                }
                
                li {
                    margin-bottom: $indent__s;
                    
                    a {
                        @include lib-font-size(14px);
                        color: $color-white;
                    }
                }
            }
        }

        #footer-social {
            a{
                display: block;
                width: 16px;
                height: 41px;
                margin: 0 29px 0 0;
                text-align: center;
                line-height: 31px;
                border-radius: 30px;
                float: left;
                font-size: 18px;

                i{
                    color: $primary__color;
                }
            }
        }

        .terms-navigation{
            display: flex;
            gap: 20px;
            border-top: 1px solid #e7e7e7;
            padding-top: 30px;
        }
        .disclaimer{
            margin-top: 50px;

            strong{
                display: block;
                width: 100%;
                position: relative;
                text-align: center;
                margin-bottom: 20px;

                &:before{
                    content:' ';
                    height: 1px;
                    width: 100%;
                    left: 0;
                    background-color: $footer_border-color;
                    position: absolute;
                    top: 11px;
                }

                span{
                    background: $gray-lightest__color;
                    position: relative;
                    display: inline-block;
                    padding: 0 10px;
                }
            }

            p{
                color: $green-dark__color;
                text-align: center;
            }
        }
    }
    &.bottom {
        @extend .abs-add-clearfix;
        padding-bottom: 30px;
        margin-top: 30px;
        text-align: center;
        font-size: 0;
        width: 100%;
        background: white;
        max-width: 100%;

        .content {

            .by {
                display: inline-block;
                color: #7b7b7b;
                margin-top: 30px;
                font-size: 11px;
                font-family: "Roboto";

                @include lib-font-size(11px);
                
                @include min-screen($screen__m) {
                    float: right;
                }
                @include max-screen($screen__m) {
                    width: 100%;
                    margin-top: $indent__base;
                }
                
                a {
                    display: inline-block;
                    margin-left: $indent__xs;
                    
                    img {
                        display: block;
                        position: relative;
                        top: 2px;
                    }
                }
            }
            .footer-cards {
                margin-top: 15px;
                
                @include min-screen($screen__l) {
                    display: inline-block;
                }
            }
            .copyright {
                display: inline-block;
                color: #7b7b7b;
                margin-top: 30px;
                font-size: 11px;
                font-family: "Roboto";

                @include min-screen($screen__m) {
                    float: left;
                }
                @include max-screen($screen__m) {
                    width: 100%;
                    margin-top: $indent__base;
                }
            }
        }
    }
}
