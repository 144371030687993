//
//  Main Header
//  ---------------------------------------------
.page-header {
    @include lib-css(background-color, false);
    position: relative;
    z-index: 35;
    background: #fff;

    .header.content {
        @extend .abs-add-clearfix;
        text-align: center;
        position: relative;
        height: 110px;

        @include max-screen($screen__l) {
            text-align: center;
            padding-top: $indent__s;
            height: 125px;
        }

        //Logo
        .logo {
            margin: 11px 0 0 0;
            display: block;
            width: 100%;
            text-align: center;

            @include max-screen($screen__m) {
                text-align: left;
                padding-left: 40px;
                width: 300px;
                margin: 15px 0 15px;
            }

            img {
                @include max-screen($screen__l) {
                    display: inline-block;
                }
            }

            .page-print & {
                float: none;
            }
        }

        #header-buttons{
            position: absolute;
            top: 36px;
            right: 0;
        }
        .header.contact {
            @include lib-font-size(14px);
            float: right;
            margin: 34px $indent__base 0 0;

            a {
                color: $text__color;
                margin-left: $indent__xs;

                .fas {
                    margin-right: $indent__xs;
                }
            }
        }

        .header.wishlist {
            @extend .abs-reset-list;
            float: left;

            @include max-screen($screen__m) {
                display: none;
            }

            & > li {
                & > a {
                    display: block;
                    width: 25px;
                    font-size: 1px;
                    height: 29px;
                    background-image: url(../images/wishlist.png);
                    color: white;
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-right: 30px;
                }
            }
        }

        .header.account {
            @extend .abs-reset-list;
            float: left;

            @include max-screen($screen__m) {
                display: none;
            }

            & > li {
                .toggle {
                    display: block;
                    width: 21px;
                    height: 29px;
                    background-repeat: no-repeat;
                    padding: 0;
                    background-image: url(../images/account.png);

                    background-position: center;
                    margin-right: 30px;

                    &:after{
                        display: none !important;
                    }
                    strong{
                        display: none;
                    }
                }

                .dropdown-menu{
                    width: 405px;
                    left: -300px;

                    &:before {
                        left: auto;
                        right: 86px;
                    }
                    &::after {
                        left: auto;
                        right: 85px;
                    }
                    .action{
                        width: 100%;

                        &.logout,
                        &.login{
                            margin-bottom: 20px;
                        }

                        &.new-customer{
                            width: 100%;
                            display: block;
                            padding: 10px;
                            color: $primary__color;
                        }
                    }
                    .title{
                        color: #919191;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                    ul{
                        list-style: none;
                        padding: 0;

                        li{
                            text-align: left;
                            padding: 0;

                            &:hover{
                                background-color: white;
                            }

                            a{
                                display: block;
                                width: 100%;
                                padding: 4px 0;
                                font-size: 14px;
                                color: $green-dark__color;

                                i{
                                    float: right;
                                }


                            }
                        }
                    }
                }
            }
        }

        .action.track-order {
            margin-left: 43px;
            margin-top: 33px;
            float: left;
            height: 43px;
            line-height: 21px;

            @include max-screen(1190px) {
                display:none;
            }

        }
    }

    #usp{
        background:#fbfbfb;
        border-bottom: 1px solid #fbfbfb;
        border-top: 1px solid #fbfbfb;
        color:#505050;
        text-align: center;
        font-size:14px;
        padding: 11px 0;

        @include max-screen($screen__m) {
            margin-bottom: 10px;
        }
        .row > div{
            position: relative;
            font-family: 'Roboto Condensed';

            @include max-screen($screen__s) {
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4){
                    display: none;
                }
            }
            @include max-screen($screen__m) {
                &:after{
                    display:none !important;
                }
            }
            &:after{
                content: ' ';
                display: block;
                width: 3px;
                height: 3px;
                transform: rotate(45deg);
                background: #babbbf;
                position: absolute;
                right: 0;
                top: 8px;
            }
            a{
                color: #505050;
            }
            &:last-child{
                &:after{
                    display: none !important;
                }
            }
        }


    }
}

    


